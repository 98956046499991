import React from 'react'
import { Link } from 'react-router-dom';
export default function contactForm() {
  return (
    <>
     {/* Section2 */}
  <section className="services-layout4 p-0">
      <div className="container">
        <div className="cat-area pt-50 pb-50 mt-60 border-top">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-6 col-xl-5">
              <p className="font-weight-bold text-gray15">HIND	 Health	 develops	 and	 provides	
                diagnostics	products	through	which	quantitative	analysis	
                and	qualitative	analysis	of	illnesses	are	possible.
              
              </p>
            </div>
            <div className="col-md-12 col-lg-6 col-xl-7  d-flex flex-wrap justify-content-xl-end">
             
            <Link className="btn bg-primary-new1 color-primery-new1 btn-xl mr-30" to= {'/contact'} >  <span>Get in touch</span>
            <i className="icon-arrow-right"></i></Link>
            
           
              <div className="contact-phone d-flex align-items-center">
                <div className="phone-icon mr-20">
                  <i className="icon-phone icon-item"></i>
                </div>
                <span className="fz-14">Contact our friendly staff or call us any time +91 11 4346 4477</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  {/* Section2 Ends */}

{/* Section3 Starts */}
<section className="contact-layout3 bg-primary-new1 ">
  
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-5 d-flex flex-column justify-content-between">
            <div>
              <div className="heading heading-light mb-30">
                <h3 className="heading-title mb-30">What Sets HIND Health Apart in Diagnostic Solutions and Services</h3>
                <p className="heading-desc">At HIND Health, we are dedicated to delivering innovative diagnostic solutions that stand out in the healthcare industry. Our commitment to excellence and customer satisfaction drives us to constantly improve and evolve.
                </p>
                <br/>
                <p className="heading-desc" >We combine creativity with cutting-edge technology, ensuring our diagnostic products provide unparalleled accuracy and reliability. Our focus on continuous research and development allows us to lead in delivering fast and effective results.</p>
              </div>
            </div>
            <div>
             
         
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-7">
            <div className="contact-panel">
              <form className="contact-panel-form" id="contactForm">
                <div className="row">
                  <div className="col-sm-12">
                    <h4 className="contact-panel-title">Get in touch with us </h4>
                    <p className="contact-panel-desc mb-30">Please feel welcome to contact our friendly reception staff
                      with any enquiry. 
                    </p>
                  </div>
    
                 
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="name">Name (required)</label>
                      <input type="text" className="form-control" placeholder="Name" id="name" required />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input type="email" className="form-control" placeholder="Email" id="email" />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="phone">Phone (required)</label>
                      <input type="text" className="form-control" placeholder="Phone" id="phone" />
                    </div>
                  </div>
                  <div className="col-sm-8 col-md-8 col-lg-8">
                    <div className="form-group form-group-date">
                      <label htmlFor="date">Massage</label>
                      <input type="text" className="form-control" placeholder="Massage" id="name" required />
                    </div>
                  </div>
                
                  <div className="col-12">
                
                    <button type="submit" className="btn bg-sec-color color-primery-new1 btn-block btn-xhight  ">
                      <span>Book Your Visit</span> <i className="icon-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
{/* Section3 ends */}
    
        </>
  )
}
