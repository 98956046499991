import React from 'react'
import Header from '../component/Header'

import Footer from '../component/Footer'
import Contactform from './../component/Contactform'

export default function about() {
  return (
    <>
    
    <Header/>
    <section className='p-0 bgaboutbanner bgaboutimage common-overlay'>
      <div className="container  ">

          <div className="row paddt">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <h1 className="pagetitle-heading z-index9">About Us</h1>
            <p className="pagetitle-desc z-index9">Hind Health is a trusted leader in diagnostic solutions, committed to delivering innovative, high-quality products that enhance patient care and improve healthcare outcomes.
            </p>

          </div>
          </div>
      </div>
      <div className="container-fluid ">
          <div className="row  banner-breadcrumb">
              <div className="col">
              <div className="breadcrumb-area">
                  <div className="container">
                    <nav className=' z-index9'>
                      <ol className="breadcrumb mb-0 page-title-light">
                        <li className="breadcrumb-item">
                          <a ><i className="icon-home color-white"></i> <span className='color-white'>Home</span></a>
                        </li>
                        <li className="breadcrumb-item color-white">
                          <a className='color-white'>About Us</a>
                        </li>
                    
                      </ol>
                    </nav>
                  </div>
                  </div>
              </div>
          </div>
      </div>

    </section>




    <section className="about-layout1 pt-130 pb-130">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-10 col-xl-7">
            <div className="heading-layout2">
              <h2 className="heading-subtitle color-body">Excellence, Dedicated, and Experienced Laboratory Technologists
              </h2>
              <h3 className="heading-title mb-50">A Trusted Healthcare Partner Providing You With High Quality Test
                Services to Manage All Health Effectively!</h3>
            </div>
            <div className="about-img">
              <div className="about-badge">
                <div className="about-icon"><i className="icon-beaker"></i></div>
              </div>
              <img src="assets/images/about/1.png" alt="about" className="w-100"/>
            </div>
          </div>
          <div className="col-sm-12 col-md-10 col-lg-10 col-xl-5">
            <div className="about-Text">
              <p className="mb-30">Established with a mission to provide reliable, high-quality diagnostic solutions, Hind Health has become a trusted name in the healthcare industry. Our focus on innovation and precision ensures that healthcare providers receive cutting-edge tools for accurate diagnoses. With a commitment to advancing the medical field, we strive to deliver products that enhance patient care and meet the highest standards.
    <br/><br/>
    Our in-house research and development, combined with advanced production facilities, allows us to stay at the forefront of diagnostics. From biochemistry to immunodiagnostics, we offer a range of solutions designed to revolutionize the diagnostic process, empowering healthcare providers to deliver better, more efficient care.
              </p>
             
           
            </div>
          </div>
        </div>
      </div>
    </section>







  <section className="banner-layout8 bg-primary-new1">
      <div className="top-shape"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-xl-5 banner-content">
            <div className="banner-text pl-3">
              <div className="heading-layout2 heading-light">
                <h3 className="heading-title"> Our Mission</h3>
                <p className="heading-desc font-weight-bold mb-40">Our mission is to provide innovative and affordable diagnostic solutions that empower healthcare providers to make faster, more accurate decisions. By delivering quick and precise results, we aim to reduce the burden of disease and improve patient outcomes. We are committed to making high-quality diagnostics accessible to all, contributing to a healthier future.
                </p>
              </div>

              <div className="heading-layout2 heading-light">
                <h3 className="heading-title"> Our Vision</h3>
                <p className="heading-desc font-weight-bold mb-40">Our vision is to become the leading diagnostics provider, revolutionizing healthcare both locally and globally. We aim to develop cutting-edge solutions that empower clinicians with the tools they need for better decision-making and equip patients with accurate information for improved health management. Through continuous innovation, we strive to transform the future of diagnostics, enhancing the quality of care worldwide.
                </p>
              </div>
          
            
            </div>
          </div>
          <div className="col-12 col-xl-7 d-flex align-items-center pl-50 pr-0">
        
            <div className="bg-img-banner">
            <img src="assets/images/banners/8.jpg" alt="backgrounds" />
            </div>
            <div className="banner-shape"></div>
          </div>
        </div>
      </div>
    </section>


    <Contactform/>
    <Footer/>
    
    </>
  )
}
