import { Link } from 'react-router-dom';
import React from 'react'

export default function Header() {
  return (
 
 <>
  


    <header className="header header-layout1">
      <div className="header-topbar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <ul className="contact-list d-flex flex-wrap align-items-center list-unstyled mb-0">
            
                  <li>
                    <i className="icon-phone"></i><a href="tel:+911143464477">+91	11 4346 4477</a>
                  </li>
                  <li>
                    <i className="icon-location"></i><a href="#">B-73, 3rd Floor,	Mayapuri	Industrial	Area,
                      Phase-I,	Mayapuri,	</a>
                  </li>
                
                </ul>
                <div className="d-flex">
                  <ul className="social-icons list-unstyled mb-0 mr-30">
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                  </ul>
           
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg sticky-navbar">
        <div className="container-fluid">
          <Link className="navbar-brand" to= {'/'} >
            <img src="assets/images/logo/logo.webp" className="logo-dark" alt="logo" />
          </Link>

          <div className="collapse navbar-collapse justify-content-center" id="mainNavigation">
            <ul className="navbar-nav">
            
              <li className="nav-item">
                <Link className="nav-item-link" to= {'/'} >Home</Link>
              </li>      
              <li className="nav-item">
                <a className="nav-item-link"><Link  className="nav-item-link" to= {'/about-us'}>About us</Link></a>
              </li>
              
              <li className="nav-item has-dropdown has-mega-dropdown">
                <a  data-toggle="dropdown" className="dropdown-toggle nav-item-link"><Link  className="nav-item-link" to= {'/product'}>Products</Link></a>
                <ul className="dropdown-menu mega-dropdown-menu">
                  <li className="nav-item">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 col-md-3 nav-block">
                          <h2 className="nav-title">Diagnostics</h2>
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">HindCheckTM Rapid Cards</a>
                                  <ul  className="nav flex-column pt-3 pb-2">
                                   <li className="nav-item"> <a className="subnevlinks  pl-2 ico1" href="#">Infectious Disease</a>   
                                    <ul  className="nav flex-column">
                                          <li><a className="subnevlinks  pl-4 ico2" href="#">Typhoid IgG/IgM</a> </li>
                                          <li><a className="subnevlinks  pl-4 ico2"  href="#">Other Infectious Disease</a></li>
                                        </ul>
                                    </li>
                                    <li> <a className="subnevlinks  pl-2 ico1" href="#">Pregnancy & Fertility</a>   
                                      <ul  className="nav flex-column">
                                            <li><a className="subnevlinks  pl-4 ico2" href="#">hCG, Urine</a> </li>
                                            
                                          </ul>
                                      </li>
                                    <li> <a className="subnevlinks  pl-2 ico1" href="#">Cardiac Markers</a>   </li>
                                    <li> <a className="subnevlinks  pl-2 ico1" href="#">Sepsis</a>   </li>
                                    <li> <a className="subnevlinks  pl-2 ico1" href="#">Rheumatology</a>   </li>

                                  </ul>
                            </li>
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">Specimen collection</a>
                                  <ul  className="nav flex-column pt-2 pb-2">
                                   <li> <a className="subnevlinks  pl-2 ico1" href="#">HindCheckTM Blood Collection Tubes</a>   
                                    <ul  className="nav flex-column">
                                          <li><a className="subnevlinks  pl-4 ico2" href="#">Non Vacuum Blood Collection Tube (Hind-Tube)</a> </li>
                                          <li><a className="subnevlinks  pl-4 ico2"  href="#">Vacuum Blood Collection Tube (Hind-Vac)</a></li>
                                        </ul>
                                    </li>
                                  </ul>
                            </li>
                            <li className="nav-item"><a className="nav-item-link" href="#"></a></li>
                          </ul>
                        </div>
                           <div className="col-sm-12 col-md-3 nav-block">
                           <h2 className="nav-title">&nbsp;</h2>
                              <ul className="nav flex-column">
        
                                    <li className="nav-item"><a className="nav-item-link" href="#">Blood Grouping</a></li>
                                    <li className="nav-item"><a className="nav-item-link" href="#">Coagulation</a></li>
                                    <li className="nav-item">
                                      <a className="nav-item-link" href="#">Blood Glucose Testing</a>
                                          <ul  className="nav flex-column pt-3 pb-3 ">
                                           <li className="nav-item"> <a className="subnevlinks  pl-2 ico1" href="#">HindCheck Pro</a></li>
                                          </ul>
                                    </li>
                                    <li className="nav-item">
                                      <a className="nav-item-link" href="#">Urine Strips</a>
                                          <ul  className="nav flex-column pt-3 pb-3 ">
                                            <li className="nav-item"> <a className="subnevlinks  pl-2 ico1" href="#">Urine Strip 2, Glu, Ket</a></li>
                                           <li className="nav-item"> <a className="subnevlinks  pl-2 ico1" href="#">Urine Strip 2A Glu, Pro</a></li>
                                           <li className="nav-item"> <a className="subnevlinks  pl-2 ico1" href="#">Urine Strip 4 </a></li>
                                           <li className="nav-item"> <a className="subnevlinks  pl-2 ico1" href="#">Urine Strip 10</a></li>
                                          </ul>
                                    </li>
                                    <li className="nav-item"><a className="nav-item-link" href="#"></a></li>
        
                                 
                                  </ul>
                                 
                                                                  
                                
                                </div>
                        <div className="col-sm-12 col-md-3 nav-block">
                          <h2 className="nav-title">Instruments</h2>
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">Clinical Analysers</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">Haematology Analysers</a>
                                  <ul  className="nav flex-column pt-3 pb-3">
                                   <li className="nav-item"> <a className="subnevlinks  pl-2 ico1" href="#">HC3</a></li>
                                   <li> <a className="subnevlinks  pl-2 ico1" href="#">HC5</a>   </li>
                                  </ul>
                            </li>
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">Photometers</a>
                                  <ul  className="nav flex-column pt-3 pb-3">
                                   <li className="nav-item"> <a className="subnevlinks  pl-2 ico1" href="#">HindChem Photometer</a></li>
                                  </ul>
                            </li>
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">Urine Strip Analysers</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">Electrolyte Analyser</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">Point Of Care Testing (POCT)</a>
                                  <ul  className="nav flex-column pt-2 pb-1">
                                   <li className="nav-item"> <a className="subnevlinks  pl-2 ico1" href="#">HindCheckTM Blood Glucose Monitoring System</a></li>
                                  </ul>
                            </li>
                            <li className="nav-item"><a className="nav-item-link" href="#"></a></li>
                          </ul>
                        </div>

                        <div className="col-sm-12 col-md-3 nav-block">
                          
                          
                          <h2 className="nav-title">&nbsp;</h2>
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">Elisa Instruments</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">Coagulation Instruments</a>
                            </li>
                          </ul>
                          
                          
                          <h2 className="nav-title">Laboratory Consumables & Disposables</h2>
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <a className="nav-item-link" href="#">Pipettes</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-item-link" href="#"> </a>
                            </li>
                          </ul>
                        </div>
                        
                        
               

                      
                       
                      
                      </div>
                    </div>
                  </li>
                  
                </ul>
                
              </li>
              
    

              <li className="nav-item">
                <a className="nav-item-link"><Link  className="nav-item-link" to= {'/contact'}>Contacts</Link></a>
              </li>
              

            </ul>
            
           
            <button className="close-mobile-menu d-block d-lg-none"><i className="fas fa-times"></i></button>
          </div>
          
          <div className="d-none d-xl-flex align-items-center position-relative ml-30">
            <div className="contact-phone d-flex align-items-center">
              <div className="contact-icon"><i className="icon-chemical9"></i></div>
              <div>
                <span className="d-block">Call Us Now:</span>
                <a className="phone-link color-primary d-block" href="tel:00201061245741">+91	11 4346 4477</a>
              </div>
            </div>
          </div>
        </div>
        
      </nav>
      
    </header>
    

   
 </>
  )
}
