import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import Sidebar from '../component/Sidebar'

export default function project() {
  return (
<>
<Header/>


<section className='p-0 bgaboutbanner bgprojectimage common-overlay'>
      <div className="container  ">

          <div className="row paddt">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <h1 className="pagetitle-heading z-index9">Our Products </h1>
            <p className="pagetitle-desc z-index9">Our diverse range of diagnostic products, from biochemistry to immunodiagnostics, provides accurate and reliable results, empowering healthcare providers with cutting-edge tools for better decision-making.
            </p>

          </div>
          </div>
      </div>
      <div className="container-fluid ">
          <div className="row  banner-breadcrumb">
              <div className="col">
              <div className="breadcrumb-area">
                  <div className="container">
                    <nav className=' z-index9'>
                      <ol className="breadcrumb mb-0 page-title-light">
                        <li className="breadcrumb-item">
                          <a ><i className="icon-home color-white"></i> <span className='color-white'>Home</span></a>
                        </li>
                        <li className="breadcrumb-item color-white">
                          <a className='color-white'>Products</a>
                        </li>
                    
                      </ol>
                    </nav>
                  </div>
                  </div>
              </div>
          </div>
      </div>

    </section>
 
 
    <section className=" py-0 pt-130 pb-130">

      <div className="container">
          <div className="row">
                
             <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
               <Sidebar/>


             </div>
             <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                  

             </div>


          </div>
        




      </div>
    </section>
    <Footer/>
</>
  )
}
