import React from 'react'

export default function Sidebar() {
  return (
   <>
   <div class="accordion" id="accordionExample">
   <div class="accordion-item">
    <h2 className='headingp1'>Diagnostics</h2>       
    </div>       
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        HindCheckTM Rapid Cards
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <ul>
            <li>Infectious Disease
                <ul>
                    <li>Typhoid IgG/IgM </li>
                    <li>Other Infectious Disease</li>
                 
                </ul>
            </li>
            <li>Pregnancy & Fertility
              <ul>
                <li>hCG, Urine</li>
              </ul>
            </li>
            <li>Cardiac Markers</li>
            <li>Sepsis</li>
            <li>Rheumatology</li>
        </ul>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Specimen collection
        </button>
      </h2>
      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        <div class="accordion-body">
         <ul>
            <li>HindCheckTM Blood Collection Tubes


                <ul>
                    <li>Non Vacuum Blood Collection Tube (Hind-Tube)</li>
                    <li>Vacuum Blood Collection Tube (Hind-Vac) </li>
                </ul>
            </li>
         </ul>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThree">
        <button class="accordion-button collapsed nonchild" type="button" >
        Blood Grouping
        </button>
      </h2>
    
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingfour">
        <button class="accordion-button collapsed nonchild" type="button" >
        Coagulation
        </button>
      </h2>

    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading5">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
        Blood Glucose Testing
        </button>
      </h2>
      <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <ul>
            <li>HindCheck Pro</li>
        </ul>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading6">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
        Urine Strips
        </button>
      </h2>
      <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
        <div class="accordion-body">
        <ul>
            <li>Urine Strip 2, Glu, Ket</li>
            <li>Urine Strip 2A Glu, Pro</li>
            <li>Urine Strip 4</li>
            <li>Urine Strip 10</li>
        </ul>
        </div>
      </div>
    </div>
    





      <div class="accordion-item">
     <h2  className='headingp1' >Instruments</h2>           
</div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="">
         <button class="accordion-button collapsed nonchild" type="button" >
         Clinical Analysers
         </button>
       </h2>
     
     </div>

     <div class="accordion-item">
       <h2 class="accordion-header" id="dheadingOnee">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dcollapseOnee" aria-expanded="true" aria-controls="dcollapseOnee">
         Haematology Analysers
         </button>
       </h2>
       <div id="dcollapseOnee" class="accordion-collapse collapse" aria-labelledby="dheadingOnee" data-bs-parent="#accordionExample">
         <div class="accordion-body">
            <ul>
                <li>HC3</li>
                <li>HC5 </li>
            </ul>
         </div>
       </div>
     </div>

     <div class="accordion-item">
       <h2 class="accordion-header" id="dheadingtwoo">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dcollapsetwoo" aria-expanded="true" aria-controls="dcollapsetwoo">
         Photometers
         </button>
       </h2>
       <div id="dcollapsetwoo" class="accordion-collapse collapse " aria-labelledby="dheadingtwoo" data-bs-parent="#accordionExample">
         <div class="accordion-body">
         <ul>
             <li>HindChem Photometer</li>
         </ul>
         </div>
       </div>
     </div>

     <div class="accordion-item">
       <h2 class="accordion-header" id="">
         <button class="accordion-button collapsed nonchild" type="button" >
         Urine Strip Analysers
         </button>
       </h2>
      </div>

     <div class="accordion-item">
       <h2 class="accordion-header" id="">
         <button class="accordion-button collapsed nonchild" type="button" >
         Electrolyte Analyser
         </button>
       </h2>
 
     </div>

     <div class="accordion-item">
       <h2 class="accordion-header" id="dheadingthree">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dcollapsethree" aria-expanded="false" aria-controls="dcollapsethree">
         Point Of Care Testing (POCT)
         </button>
       </h2>
       <div id="dcollapsethree" class="accordion-collapse collapse" aria-labelledby="dheadingthree" data-bs-parent="#accordionExample">
         <div class="accordion-body">
         <ul>
             <li>HindCheckTM Blood Glucose Monitoring System</li>
         </ul>
         </div>
       </div>
     </div>

     <div class="accordion-item">
       <h2 class="accordion-header" id="">
         <button class="accordion-button collapsed nonchild" type="button" >
         Elisa Instruments
         </button>
       </h2>
 
     </div>

     <div class="accordion-item">
       <h2 class="accordion-header" id="">
         <button class="accordion-button collapsed nonchild" type="button" >
         Coagulation Instruments
         </button>
       </h2>
 
     </div>
     
     <div class="accordion-item">
     <h2  className='headingp1' >Laboratory Consumables & Disposables</h2>           
</div>
 
 
<div class="accordion-item">
       <h2 class="accordion-header" id="">
         <button class="accordion-button collapsed nonchild" type="button" >
         Pipettes
         </button>
       </h2>
 
     </div>
 
 
   </div></>
  )
}
