import React from 'react'
import { Link } from 'react-router-dom';
import Contactform from './../component/Contactform'


export default function Homesec() {
  return (
    <>
    {/* Section1 */}
   
    {/* Section1 */}
    <section className="banner-layout8 bg-primary-new1">
      <div className="top-shape"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-xl-5 banner-content">
            <div className="banner-text pl-3">
              <div className="heading-layout2 heading-light">
                <h3 className="heading-title"> Our Commitment to Innovation and Quality</h3>
                <p className="heading-desc font-weight-bold mb-40">At HIND Health, we believe in combining creativity with cutting-edge technology to develop diagnostic solutions that offer unparalleled accuracy. Our state-of-the-art facilities, highly skilled professionals, and customer-first approach set us apart from the competition. With continuous R&D, we are leading the way in diagnostics that provide fast and reliable results.
                </p>
              </div>
              <div className="d-flex flex-wrap mb-90">
              <Link className="btn btn-white font-weight-bold btn-white-style2 mr-30" to= {'/about-us'} ><span>More Info</span> <i className="icon-arrow-right"></i></Link>
              <Link className="btn btn-light btn-outlined" to= {'/contact'} >Contact Us</Link>
          
              </div>
              <div className="fancybox-layout2 fancybox-light">
                <div className="fancybox-item">
                  <div className="fancybox-icon">
                    <i className="icon-chemistry"></i>
                  </div>
                  <div className="fancybox-body">
                    <h4 className="fancybox-title">Tailored Solutions</h4>
                    <p className="fancybox-desc">We understand that every healthcare provider has unique needs. Our customizable diagnostic products are designed to meet specific requirements, ensuring that our clients receive the most effective solutions.</p>
                  </div>
                </div>
                <div className="fancybox-item">
                  <div className="fancybox-icon">
                    <i className="icon-drug"></i>
                  </div>
                  <div className="fancybox-body">
                    <h4 className="fancybox-title">Unmatched Support</h4>
                    <p className="fancybox-desc">Our dedicated customer support team is always available to assist healthcare professionals, providing training, troubleshooting, and ongoing guidance to ensure optimal use of our products.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-7 d-flex align-items-center pl-50 pr-0">
            <div className="bg-img-banner">
            <img src="assets/images/banners/8.jpg" alt="backgrounds" />
            </div>
          
            <div className="banner-shape"></div>
          </div>
        </div>
      </div>
    </section>
{/* Section1 ends */}

<section className="services-layout4 pb-5">

<div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
          <div className="heading-layout2 text-center mb-50">
              <h2 className="heading-subtitle">Our Services</h2>
              <h3 className="heading-title color-primary">Extensive Range of Diagnostic Solutions</h3>
            </div>            
          </div>
          </div>
          <div className="row p-0">
              <div className="wid31 p-0 cartst" >   
                          <div className="container p-5 pb-0 over-hidden">
                              <div className="row">
                              <div className="col-12 bg-icon">
                                  <i className="icon-biology6 bg-icon-style icon-item"></i>
                                  <i className="icon-biology6 bg-icon-style-bg icon-item"></i>
                                  </div>    
                                      <div className="col-12">
                                            <h4 className="service-title-1 ">
                                            <a href="#" >Biochemistry</a>
                                            </h4>
                                      </div>    
                                      <div className="col-12">
                                            <p className="service-desc-1">Advanced solutions for clinical analysis.</p>
                                            
                                      </div>    
                              </div>
                          </div>

                          <div className="container p-4 pt-0 pb-0">
                              <div className="row">
                                  <div className="col-12 serv-imagestyle">
                                     <img src="assets/images/services/8.jpg" alt="service" />
                                  
                            </div>     
                              </div>
                          </div>

              </div> 

              <div className="wid31 p-0 cartst " >   
                          <div className="container p-5 pb-0 over-hidden">
                              <div className="row">
                              <div className="col-12 bg-icon">
                                  <i className="icon-chemical4 bg-icon-style icon-item"></i>
                                  <i className="icon-chemical4 bg-icon-style-bg icon-item"></i>
        
                                  </div>    
                                      <div className="col-12">
                                            <h4 className="service-title-1 ">
                                            <a href="#" >Hematology</a>
                                            </h4>
                                      </div>    
                                      <div className="col-12">
                                            <p className="service-desc-1">Comprehensive diagnostic products for blood analysis.</p>
                                            
                                      </div>    
                              </div>
                          </div>

                          <div className="container p-4 pt-0 pb-0">
                              <div className="row">
                                  <div className="col-12 serv-imagestyle">
                                     <img src="assets/images/services/9.jpg" alt="service" />
                                  
                            </div>     
                              </div>
                          </div>

              </div> 

              <div className="wid31 cartst" >   
                          <div className="container p-5 pb-0 over-hidden">
                              <div className="row">
                              <div className="col-12 bg-icon">
                                  <i className="icon-allotropy   bg-icon-style icon-item"></i>
                                  <i className="icon-allotropy bg-icon-style-bg icon-item"></i>
                                  </div>    
                                      <div className="col-12">
                                            <h4 className="service-title-1 ">
                                            <a href="#" >Critical Care</a>
                                            </h4>
                                      </div>    
                                      <div className="col-12">
                                            <p className="service-desc-1">Diagnostics to support critical health conditions.</p>
                                            
                                      </div>    
                              </div>
                          </div>

                          <div className="container p-4 pt-0 pb-0">
                              <div className="row">
                                  <div className="col-12 serv-imagestyle">
                                     <img src="assets/images/services/8.jpg" alt="service" />
                                  
                            </div>     
                              </div>
                          </div>

              </div> 
              
          </div>
</div>
</section>




  <Contactform/>



  


  </>
  

  )
}
