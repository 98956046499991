import React from 'react'
import { Link } from 'react-router-dom';
import Header from '../component/Header'
import Banner from '../component/Banner'
import Homesec from '../component/Homesec'
import Footer from '../component/Footer'
import { useEffect } from 'react';
import { Suspense, lazy } from 'react';



function Home() {


  useEffect(() => {
    // JavaScript initialization logic (e.g., sliders, animations, event listeners)
    console.log("Home JS initialized");

    return () => {
      // Clean up if necessary
      console.log("Cleaning up Home JS");
    };
  }, []);

  return (
    <>
  <Header/>
    <Banner/>
    
     <section class="about-layout2 pt-130 pb-130">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8">
          <div class="about-text mb-30">
            <div class="about-badge">
              <div class="about-icon"><i class="icon-beaker"></i></div>
            </div>
            <div class="about-text-bannera ab-t-banner">
              <div class="heading-layout2">
                <h3 class="heading-title color-primary mb-0">A Trusted Healthcare Partner Providing You With High Quality Test
                  Kits! </h3>
              </div>
            </div>
          </div>
          <div class="about-img d-flex justify-content-end">
            <img src="assets/images/about/2.png" alt="about" />
          </div>
        </div>
        <div class="col-sm-12 col-md-10 col-lg-8 col-xl-4">
          <div class="about-Text">
            <h2 class="heading-subtitle mar-top50">About Hind Health Products </h2>
            <h3 class="heading-title color-primary">Excellence in Diagnostics</h3>
            <p class="mb-30"> HIND Health is a leader in in-vitro diagnostics, providing innovative, accurate, and affordable solutions to medical professionals. With our state-of-the-art manufacturing and a dedicated team, we aim to shape the future of healthcare diagnostics.
            </p>
            <Link className="btn bg-primary-new1 btn-xl mr-30  color-primery-new1" to= {'about-us'} > <span>Know more</span>
            <i class="icon-arrow-right"></i></Link>

       
          </div>
        </div>
      </div>
    </div>
  </section>
    <Homesec/>
    <Footer/>

  </>
  )
}

export default Home
