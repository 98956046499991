import React from 'react'
import { Link } from 'react-router-dom'
export default function Footer() {
  return (

    <footer className="footer">
    <div className="footer-primary">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-3">
            <div className="footer-widget-contact">
              <h6 className="footer-widget-title">Quick Contacts</h6>
              <p>If you have any questions or need help, feel free to contact with our team.</p>
              <ul className="contact-list list-unstyled">
                <li>
                  <a href="mailto:info@hindhealthproduct.com">
                    <i className="contact-icon icon-email"></i> <span>info@hindhealthproduct.com</span>
                  </a>
                </li>
                <li>
                  <a href="tel:+911143464477">
                    <i className="contact-icon icon-phone"></i> <span>+91	11 4346	4477</span>
                  </a>
                </li>
              </ul>
              <p>B-73, 3rd Floor,	Mayapuri	Industrial	Area,
                Phase-I,	Mayapuri,	New	Delhi	- 110064.</p>
        
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3 hidediv">
            <div className="footer-widget-nav">
         
            
            </div>
          </div>
        
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer-widget-nav">
              <h6 className="footer-widget-title">Links</h6>
              <nav>
                <ul className="list-unstyled">
                  <li><a href="index.html">Home</a></li>
                  <li><a href="about-us.html">About us</a></li>
                  <li><a href="products.html">Products </a></li>
                  <li><a href="contact-us.html">Contact us </a></li>
                  
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer-widget-time">
              <h6 className="footer-widget-title">Working Hours</h6>
              <ul className="time-list list-unstyled">
                <li>
                  <span className="day">Week Days</span><span className="time">09.00 - 07:00</span>
                </li>
                <li>
                  <span className="day">Saturday</span><span className="time">09:00 - 06:00</span>
                </li>
                <li>
                  <span className="day">Sunday</span><span className="time">Day off</span>
                </li>
              </ul>
              <div className="d-flex align-items-center">
               
              <Link className="btn btn-primary btn-block bg-sec-color" to= {'/contact'} >    
              <span>Book Your Visit</span> 
              <i className="icon-arrow-right"></i>
              </Link> 
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-secondary">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 d-flex flex-wrap">
            <ul className="social-icons list-unstyled mb-0 mr-50">
              <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="#"><i className="fab fa-instagram"></i></a></li>

            </ul>
            <div>
              <nav>
                <ul className="list-unstyled footer-copyright-links d-flex flex-wrap mb-0">
                  <li><a href="#">Privacy Policy</a></li>
                  <li><a href="#">Terms & Condition</a></li>
                
                </ul>
              </nav>
              <div className="mt-1">
                <span>&copy; 2023 Hindhealthproducts , All Rights Reserved. With Love by</span>
                <a className="color-secondary" href="http://cybss.com">cybss</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
    

  )
}
