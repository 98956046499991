import React from 'react'
import banImg1 from  '../images/banner1.webp'
import banImg2 from  '../images/banner2.webp'
import banImg3 from  '../images/banner3.webp'

export default function Banner() {
  return (
<>
<section id='homebanner' className='p-0 m-0' >
<div id="carouselExampleCaptions" className="carousel slide">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner bg-overlay">
    <div className="carousel-item active">
      <img src={banImg1} className="d-block w-100 " alt="..."/>
      <div className="carousel-caption d-none d-md-block newcap">
        <h5>Innovative Technology</h5>
        <p>We provide cutting-edge tools for quick, reliable illness detection, improving healthcare outcomes globally.</p>

      </div>
    </div>
    <div className="carousel-item">
      <img src={banImg2} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block newcap">
        <h5>Diagnostic Excellence</h5>
        <p>Our state-of-the-art facilities ensure the highest quality in diagnostic solutions, meeting global standards.</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src={banImg3} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block newcap">
        <h5>Trusted Quality</h5>
        <p>We deliver high-quality, affordable diagnostics that empower healthcare professionals to make timely decisions.</p>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

</section>

   
  </>

    
  )
}
