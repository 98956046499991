import React from 'react'
import Header from '../component/Header'

import Footer from '../component/Footer'
export default function contact() {
  return (
<>
<Header/>
<section className="map py-0">
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14008.840730105472!2d77.10936723710614!3d28.623462102871606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0338eaaaaaab%3A0x984db1090a4aae5f!2sIndia%20International%20Marketing%20Company%20(IIMC%20MACHINES)!5e0!3m2!1sen!2sin!4v1729086131074!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <div className="map-container">
        <div className="contact-panel p-0">
          <div className="panel-header">
            <h3 className="panel-title color-white mb-0">Offices </h3>
          </div>
          <div className="accordion" id="accordion">
            <div className="accordion-item opened">
              <div className="accordion-header" data-toggle="collapse" data-target="#collapse1">
                <a className="accordion-title" href="#">Delhi Office</a>
              </div>
              <div id="collapse1" className="collapse show" data-parent="#accordion">
                <div className="accordion-body">
                  <ul className="contact-list list-unstyled mb-0">
                    <li>Phone: +91 11 4346 4477</li>
                    <li>Email: info@hindhealthproduct.com</li>
                    <li>Address: B-73, 3rd Floor, Mayapuri Industrial Area, Phase-I, Mayapuri, New Delhi - 110064.</li>
                   
                  </ul>
                </div>
              </div>
            </div>
       
   
          </div>
        </div>
      </div>
    </section>

    <section className="contact-layout1">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-7">
            <div className="heading-layout2 mb-50">
              <h2 className="heading-subtitle">Contact Us And We Will Respond asap</h2>
              <h3 className="heading-title">Get In Touch With us
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="text-block">
              <p className="text-block-desc">We’re here to help! Whether you have questions about our products, need support, or want to explore partnership opportunities, our team is ready to assist you. At Hind Health, we value your feedback and are committed to providing prompt, personalized service.</p>
              
            </div>
          
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <form className="contact-panel-form" method="post" action="assets/php/contact.php" id="contactForm">
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Name" id="contact-name" name="contact-name"
                      required />
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="form-group">
                    <input type="email" className="form-control" placeholder="Email" id="contact-email" name="contact-email"
                      required />
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Phone" id="contact-Phone" name="contact-phone"
                      required />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <textarea className="form-control" placeholder="Additional Details" id="contact-message"
                      name="contact-message"></textarea>
                  </div>
                  <button type="submit" className="btn btn-secondary  btn-block btn-xhight mt-10">
                    <span>Submit Request</span> <i className="icon-arrow-right"></i>
                  </button>
                  <div className="contact-result"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <Footer/>
</>
  )
}
